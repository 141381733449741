import React from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./howToGetCard.module.scss"
import { Props, STEPS } from "./helpers"

const titleDefault = (
  <>
    Как оформить карту <br className="d-sm-none" /> рассрочки «Халва»
  </>
)

export const HowToGetCard = ({ orderNum, title = titleDefault, items = STEPS }: Props) => (
  <section className={styles.section} data-exclude={orderNum}>
    <Container className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <div>
        <ul className={styles.steps}>
          {items.map((item, i) => (
            <li key={i} className={styles.step}>
              <p className={styles.text}>{item.title}</p>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  </section>
)
