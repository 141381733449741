import React, { ReactNode } from "react"

type ItemsProps = {
  title: ReactNode
}

export type Props = {
  orderNum?: string
  title?: ReactNode
  items?: ItemsProps[]
}

export const STEPS = [
  {
    title: (
      <>
        Оставьте заявку. <span>Онлайн за&nbsp;несколько&nbsp;минут</span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите карту. <span>Доставкой или&nbsp;в&nbsp;отделении банка</span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте карту. <span>Совершив покупку в&nbsp;партнерском магазине</span>
      </>
    ),
  },
]

export const STEPS_MEDIA_D = [
  {
    title: (
      <>
        Оставьте заявку. <span>Онлайн за&nbsp;несколько&nbsp;минут</span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите карту. <span>Доставкой или&nbsp;в&nbsp;отделении банка</span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте карту. <span>Совершив покупку в&nbsp;партнерском магазине</span>
      </>
    ),
  },
]
